import Vue from 'vue/dist/vue.esm.js';

Vue.component('user-address', {
    template: `#user-address`,
    delimiters: ['[[', ']]'],
    props: {
        delivery: {
            required: true,
            type: Array,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        fetchOfficesUrl: {
            required: true,
            type: String,
        },
    },



    data() {
        return {
            countries: this.delivery,
            selectedCountry: null,
            selectedType: null,
            selectedArea: null,
            selectedOption: null,
            selectedSlot: null,
            selectedCity: null,
            isSlotSelecting: false,
            noOfficesWarning: false,
        }
    },

    methods: {

        validateRef(ref) {
            if (this.$refs[ref] !== undefined) {
                return true;
            }
            return false;
        },

        selectType(type) {

            if (this.selectedType === type) {
                return;
            }

            this.selectedType = type;

            this.noOfficesWarning = false;
            this.selectedArea = null;
            this.selectedOption = null;
            this.selectedCity = null;

            if (this.selectedType.areas.length === 1) {
                this.selectArea(this.selectedType.areas[0])
                //this.selectedArea = this.selectedType.areas[0];
            }
        },
        selectCountry(country) {
            if (this.selectedCountry === country) {
                return;
            }
            this.selectedCountry = country;
            this.countryChanged();
        },
        selectArea(area, refreshCity = false) {

            if (this.selectedArea === area) {
                return;
            }
            this.selectedArea = area;
            this.selectedOption = null;
            this.deliveryPrice = null;

            let select2 = $(this.$refs.cityField);

            if (refreshCity) {
                select2.val(null).trigger('change');
            }
            if (area.city.id) {
                this.selectedCity = area.city.id;
                select2.attr('readonly', 'readonly');
                this.preselectCity(area.city);
            } else {
                select2.removeAttr('readonly');
            }
        },

        selectOption(option) {
            if (this.selectedOption === option) {
                return;
            }
            this.selectedOption = option;
            this.deliveryPrice = option.price;

            if (this.$refs.cityField && this.$refs.cityField.value) {
                this.selectedCity = this.$refs.cityField.value;
            }

            this.tryToPopulateOffices(true);
        },


        removeOptions(selectElement, clearSelected = false) {
            if (!selectElement) {
                return;
            }

            let i, Length = selectElement.options.length - 1;
            for (i = Length; i >= 1; i--) {

                if (selectElement.options[i].selected == true && !clearSelected) {
                    continue;
                }

                selectElement.remove(i);

            }
        },

        countryChanged() {

            let select2 = $(this.$refs.cityField);
            if(select2.length){
                select2.select2('destroy');
            }

            this.selectedType = null;
            this.noOfficesWarning = false;
            this.selectedArea = null;
            this.selectedOption = null;
            this.selectedCity = null;
            this.deliveryPrice = null;
            this.selectedPaymentMethod = null;
            this.selectedCard = null;


            if (this.selectedCountry.types.length === 1) {
                this.selectType(this.selectedCountry.types[0])
            }
        },

        tryToPopulateOffices(clearSelected = false) {

            if (this.selectedCity && this.selectedOption && this.selectedType.isOffice) {
                let officeSelect = $(this.$refs.officeField);
                let citySelect = $(this.$refs.cityField);
                this.noOfficesWarning = false;
                this.postData(this.fetchOfficesUrl, {courierId: this.selectedOption.courier.id, cityId: this.selectedCity})
                    .then(data => {
                        this.removeOptions(this.$refs.officeField, clearSelected);
                        if (!data.results) {
                            this.noOfficesWarning = true;
                            this.selectedCity = null;
                            citySelect.val(null).trigger('change');

                            return;

                        }
                        for (let each of data.results) {
                            let newOption = new Option(each.text, each.id, false, false);
                            officeSelect.append(newOption);
                        }
                    });

            }
        },

        async postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        },


        cityChanged(data) {
            this.selectedCity = data;
            this.tryToPopulateOffices(true);
        },


        toggleSlotSelection() {
            this.isSlotSelecting = !this.isSlotSelecting;
            if (!this.isSlotSelecting) {
                this.selectedSlot = null;
            }

        },

        preselectCity(city) {

            let select2 = $(this.$refs.cityField);
            let newOption = new Option(city.name, city.id, true, true);

            select2.append(newOption).trigger('change');
        },

        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },

        calcDeliveryPrice(price) {
            return this.formattedPrice(price);
        },
        calcOptionDeliveryPrice(option) {

            if (option === this.selectedOption && !option.allowFreeShipping) {
                return this.formattedPrice(option.price);
            }

            if (!option.allowFreeShipping && this.selectedOption !== option) {
                return this.formattedPrice(option.price);
            }

            return this.formattedPrice(option.price);

        },

        formattedDate(datetimeString) {
            let date = new Date(datetimeString);
            return new Intl.DateTimeFormat(this.locale, {day: 'numeric', month: 'numeric', weekday: 'long'}).format(date);
        },

        formattedSlotTime(slot) {
            let startDate = new Date(slot.startTime.date);
            let endDate = new Date(slot.endTime.date);

            startDate = Intl.DateTimeFormat(this.locale, {timeStyle: 'short'}).format(startDate);
            endDate = Intl.DateTimeFormat(this.locale, {timeStyle: 'short'}).format(endDate);
            return startDate + ' - ' + endDate;
        },


        shouldShowAddressFields() {

            if (this.selectedType && this.selectedType.isPhysical === true) {
                return true;
            }
            return false;
        }

    },

    computed: {
        formattedTotal() {
            if (this.qualifiesForFreeDelivery()) {
                return this.formattedPrice(this.calcTotal(false))
            }

            return this.formattedPrice(this.calcTotal(true))

        },

        computedDeliveryPrice() {
            if (this.qualifiesForFreeDelivery()) {
                return 0;
            }
            return this.deliveryPrice;
        },
        formattedOriginalTotal() {
            return this.formattedPrice(this.cart.originalTotal - (this.cart.originalTotal / 6));
        },
        formattedVAT() {
            return this.formattedPrice(this.cart.originalTotal / 6);
        },
        amountToFreeShipping() {
            return this.formattedPrice(this.freeDeliveryOver - this.cart.total);
        },
        formattedDiscount() {
            return '-' + this.formattedPrice(this.cart.discount);
        },
        selectedSlotId() {
            if (this.selectedSlot) {
                return this.selectedSlot.id;
            } else {
                return null;
            }
        }
    }
});