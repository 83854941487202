import Vue from 'vue/dist/vue.esm.js';
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm';
import {bg, en} from 'vuejs-datepicker/dist/locale'

import 'select2/dist/css/select2.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';
import 'select2';
Vue.component('datepicker', Datepicker);

Vue.component('checkout', {
    template: `#checkout`,
    delimiters: ['[[', ']]'],
    props: {
        initialCart: {
            required: true,
            type: Object,
        },
        packageOptions: {
            required: true,
            type: Array,
        },
        delivery: {
            required: true,
            type: Array,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        removeRowUrl: {
            required: true,
            type: String,
        },
        incrementUrl: {
            required: true,
            type: String,
        },
        decrementUrl: {
            required: true,
            type: String,
        },
        promocodeUrl: {
            required: true,
            type: String,
        },
        removePromocodeUrl: {
            required: true,
            type: String,
        },
        fetchOfficesUrl: {
            required: true,
            type: String,
        },
        uniqueEventId: {
            required: false,
            type: String,
        },
        hasUser: {
            required: true,
            type: Number,
        },
        userAddresses: {
            required: false,
            type: Array,
        },
        userCards: {
            required: false,
            type: Array,
        }
    },

    mounted() {


        let _self = this;
        let select2City = $(this.$refs.cityField);
        select2City.on('change', function (e) {
            if (this.isGift) {
                _self.changeReceiverData();
            } else {
                _self.changeUserData();
            }
        });



        this.initIsGift();
        this.initSaveAddress();
        this.initAllowNewAddress();

        if (parseInt(this.$refs.packaging.dataset.val)) {
            this.packaging = parseInt(this.$refs.packaging.dataset.val);
        }
        if (this.$refs.selectedDate.dataset.val) {
            this.selectDate(this.$refs.selectedDate.dataset.val);
        }
        if (parseInt(this.$refs.selectedCountry.dataset.val)) {
            this.selectCountry(this.delivery.filter(d => d.id === parseInt(this.$refs.selectedCountry.dataset.val))[0]);
        }

        if (parseInt(this.$refs.selectedType.dataset.val)) {
            this.selectType(this.selectedCountry.types.filter(d => d.id === parseInt(this.$refs.selectedType.dataset.val))[0], false);
        }


        if (parseInt(this.$refs.existingAddress.dataset.val)) {
            this.selectUserAddress(this.userAddresses.filter(d => d.id === parseInt(this.$refs.existingAddress.dataset.val))[0]);
        }else{
            this.selectUserAddress(this.userAddresses[0]);
        }

        if (parseInt(this.$refs.selectedArea.dataset.val)) {
            this.selectArea(this.selectedType.areas.filter(d => d.id === parseInt(this.$refs.selectedArea.dataset.val))[0], false);
        }

        if (parseInt(this.$refs.selectedOption.dataset.val)) {
            this.selectOption(this.selectedArea.options.filter(d => d.id === parseInt(this.$refs.selectedOption.dataset.val))[0], false, false);

        }

        if (parseInt(this.$refs.selectedPaymentMethod.dataset.val)) {
            this.selectPaymentMethod(this.selectedOption.paymentMethods.filter(d => d.id === parseInt(this.$refs.selectedPaymentMethod.dataset.val))[0]);
        }

        if (parseInt(this.$refs.selectedPackage.dataset.val)) {
            this.selectPackage(this.packageOptions.filter(d => d.id === parseInt(this.$refs.selectedPackage.dataset.val))[0]);
        }


        if (parseInt(this.$refs.selectedCard.dataset.val)) {
            this.selectCard(this.userCards.filter(d => d.id === parseInt(this.$refs.selectedCard.dataset.val))[0]);
        }

        if (this.$refs.cityField && this.$refs.cityField.value) {
            this.selectedCity = this.$refs.cityField.value;
        }

        if (this.$refs.cityValue && this.$refs.cityValue.value) {
            this.selectedCity = this.$refs.cityValue.value;
        }

        if(!this.selectedType){
            this.selectedType = this.selectedCountry['types'][0];
            this.selectedArea = this.selectedType['areas'][0];
            this.selectedOption = this.selectedArea['options'][0];
        }


        EventManager.listen('item-added', this.cartUpdated);
        EventManager.listen('order-address:city', this.cityChanged);
        EventManager.listen('item-removed', this.cartUpdated);
        EventManager.listen('load-stimulus', this.loadStimulus);

        this.initCountrySelect2();
    },


    data() {
        return {
            countries: this.delivery,
            cart: this.initialCart,
            showPromocode: false,
            hasPromocode: !!this.initialCart.promocode,
            promocode: this.initialCart.promocode,
            promocodeText: null,
            promocodeError: null,
            selectedCountry: this.delivery[0],
            selectedType: null,
            selectedDate: null,
            selectedArea: null,
            selectedOption: null,
            selectedPackage: null,
            selectedSlot: null,
            selectedCity: null,
            selectedUserAddress: null,
            selectedPaymentMethod: null,
            prevSelectedPaymentMethod: null,
            selectedCard: null,
            allowNewAddress: 0,
            deliveryPrice: null,
            deliveryPriceInCurrentCurrency: null,
            deliveryPriceDiscount: null,
            isGift: 0,
            saveAddress: 0,
            isSlotSelecting: false,
            packaging: 0,
            packages: this.packageOptions,
            quantityAlert: {
                visible: false,
                productName: '',
            },
            noOfficesWarning: false,
            en: en,
            bg: bg,
            disabledDates: {
                to: new Date((new Date()).setDate(new Date().getDate() + 2)),
                days: [6, 0],
            },
        }
    },

    methods: {

        initCountrySelect2(){
            let _self = this;
            let countrySelect = $(this.$refs.countrySelect);
            countrySelect.select2({
                theme: 'bootstrap4'
            });

            $(countrySelect).on("select2:select", function (e) {
                let country = _self.countries.filter(c => c.id == e.params.data.id)[0];
                _self.selectCountry(country);
            });
        },
        loadStimulus() {
            let eventStep1 = {
                event: 'eec.checkout',
                eventData: 'Cart Page',
                unique_event_id: this.uniqueEventId,
                action: 1,
                cart: this.initialCart
            }
            EventManager.fire('gtm-event', eventStep1);

            let isGift = 'not a gift';

            let eventStep2 = {
                event: 'eec.checkout',
                eventData: isGift,
                action: 2,
            }
            EventManager.fire('gtm-event', eventStep2);
        },
        initIsGift() {
            let value;
            if (this.validateRef("isGift")) {
                if (isNaN(parseInt(this.$refs.isGift.dataset.val))) {
                    value = null;
                } else {
                    value = parseInt(this.$refs.isGift.dataset.val);
                }
            }
            this.isGift = value;
        },
        selectDate(date) {
            this.selectedDate = date;
        },
        initSaveAddress() {
            let value;
            if (this.validateRef("saveAddress")) {
                if (this.$refs.saveAddress.dataset.val === '') {
                    return;
                }
                if (isNaN(parseInt(this.$refs.saveAddress.dataset.val))) {
                    value = null;
                } else {
                    value = parseInt(this.$refs.saveAddress.dataset.val);
                }
            }
            this.saveAddress = value;
        },
        initAllowNewAddress() {
            let value = this.allowNewAddress;
            if (this.validateRef("allowNewAddress")) {
                if (this.$refs.allowNewAddress.dataset.val === '') {
                    return;
                }
                if (isNaN(parseInt(this.$refs.allowNewAddress.dataset.val))) {
                    value = null;
                } else {
                    value = parseInt(this.$refs.allowNewAddress.dataset.val);
                }
            }
            this.allowNewAddress = value;
        },

        validateRef(ref) {
            if (this.$refs[ref] !== undefined) {
                return true;
            }
            return false;
        },

        selectType(type) {
            if (this.selectedType === type) {
                return;
            }

            this.selectedType = type;
            this.initStep3(type);

            this.noOfficesWarning = false;
            this.selectedArea = null;
            this.selectedOption = null;
            this.selectedCity = null;
            this.deliveryPrice = null;
            this.selectedPaymentMethod = null;
            this.selectedCard = null;

            if (this.selectedType.areas.length === 1) {
                this.selectArea(this.selectedType.areas[0])
            }

            //$(this.$refs.cityField).select2('destroy');

            if (!this.selectedType.isOffice) {
                if (this.$refs.officeField) {
                    $(this.$refs.officeField).select2('destroy');
                }
            }
        },
        countryChanged() {


            let select2 = $(this.$refs.cityField);
            select2.select2('destroy');

            this.selectedType = null;
            this.noOfficesWarning = false;
            this.selectedArea = null;
            this.selectedOption = null;
            this.selectedCity = null;
            this.deliveryPrice = null;
            this.selectedPaymentMethod = null;
            this.selectedCard = null;


            if (this.selectedCountry.types.length === 1) {
                this.selectType(this.selectedCountry.types[0])
            }
        },

        selectCountry(country) {
            if (this.selectedCountry === country) {
                return;
            }
            this.selectedCountry = country;
            this.countryChanged();
        },

        initStep3(type) {
            let textType = "address";
            if (type.isOffice) {
                textType = "office";
            } else if (type.isPhysical) {
                textType = "click and collect";
            }

            let eventStep3 = {
                event: 'eec.checkout',
                eventData: textType,
                action: 3,
            }
            EventManager.fire('gtm-event', eventStep3);
        },
        selectArea(area, refreshCity = false) {
            if (this.selectedArea === area) {
                return;
            }
            this.selectedArea = area;
            this.selectedOption = null;
            this.deliveryPrice = null;

            this.noOfficesWarning = false;
            this.selectedPaymentMethod = null;
            this.prevSelectedPaymentMethod = null;
            this.selectedCard = null;
            this.selectedCity = null;

            let select2 = $(this.$refs.cityField);

            if (refreshCity) {
                select2.val(null).trigger('change');
            }
            if (area.city.id) {
                this.selectedCity = area.city.id;

                select2.attr('readonly', 'readonly');

                this.preselectCity(area.city);
            } else {
                select2.removeAttr('readonly');
            }

            select2.on('select2:opening', function (e) {
                if ($(e.target).attr('readonly')) {
                    e.preventDefault();
                }
            });
        },
        initStep4(option) {
            let optionText = "";
            switch (option.id) {
                case 1:
                case 2:
                case 3:
                    optionText = "masterpointpress";
                    break;
                case 4:
                    optionText = 'econt address';
                    break;
                case 5:
                    optionText = "speedy address";
                    break;
                case 6:
                    optionText = "econt office ";
                    break;
                case 7:
                    optionText = "speedy office";
                    break;
                case 8:
                    optionText = "click and collect - gallery";
                    break;
                case 9:
                    optionText = "click and collect - business park";
                    break;
                default:

            }

            let eventStep4 = {
                event: 'eec.checkout',
                eventData: optionText,
                action: 4,
            }
            EventManager.fire('gtm-event', eventStep4);
        },

        selectOption(option, clearSelected, removeOptions = true) {
            this.showPaymentTooltip();
            this.initStep4(option);

            if (this.selectedOption === option) {
                return;
            }
            this.selectedOption = option;

            this.deliveryPrice = option.price;
            this.deliveryPriceInCurrentCurrency = option.priceInCurrentCurrency;

            if (this.$refs.cityField && this.$refs.cityField.value) {
                this.selectedCity = this.$refs.cityField.value;
            }

            if (!this.isGift && this.hasUser) {
                this.saveAddress = 1;
            }
            this.tryToPopulateOffices(clearSelected, removeOptions);
        },
        changeUserData() {
            let eventStep5 = {
                event: 'eec.checkout',
                eventData: "customer details",
                action: 5,
            }
            EventManager.fire('gtm-event', eventStep5);
        },
        changeReceiverData() {
            let eventStep5 = {
                event: 'eec.checkout',
                eventData: "customer details - gift",
                action: 5,
            }
            EventManager.fire('gtm-event', eventStep5);
        },
        agreeTerms() {
            let eventStep7 = {
                event: 'eec.checkout',
                eventData: "terms and conditions",
                action: 7,
            }
            EventManager.fire('gtm-event', eventStep7);
        },
        selectUserAddress(address) {

            if(!address){
                return;
            }
            this.showPaymentTooltip();
            if (this.selectedUserAddress === address) {
                return;
            }
            this.selectedUserAddress = address;

            this.saveAddress = 0;

            this.selectedCountry = address.country;
            this.selectedType = address.type;
            this.selectedArea = address.area;
            if (address.cityId) {
                this.selectedCity = address.cityId;
            }
            this.selectedOption = address.deliveryOption;
            this.deliveryPrice = address.deliveryOption.price;
            this.deliveryPriceInCurrentCurrency = address.deliveryOption.priceInCurrentCurrency;

            this.initStep3(address.type);
            this.initStep4(address.deliveryOption);
            this.changeUserData();

        },
        removeOptions(selectElement, clearSelected = false) {
            if (!selectElement) {
                return;
            }

            let i, Length = selectElement.options.length - 1;
            for (i = Length; i >= 1; i--) {

                if (selectElement.options[i].selected == true && !clearSelected) {
                    continue;
                }

                selectElement.remove(i);

            }
        },

        tryToPopulateOffices(clearSelected = false, removeOptions = true) {

            if (this.selectedCity && this.selectedOption && this.selectedType.isOffice) {
                let officeSelect = $(this.$refs.officeField);
                let select2Office = $(this.$refs.officeField);
                let _self = this;
                select2Office.on('change', function (e) {
                    if (this.isGift) {
                        _self.changeReceiverData();
                    } else {
                        _self.changeUserData();
                    }
                });
                let citySelect = $(this.$refs.cityField);
                this.noOfficesWarning = false;
                this.postData(this.fetchOfficesUrl, {
                    courierId: this.selectedOption.courier.id,
                    cityId: this.selectedCity
                })
                    .then(data => {
                        if (removeOptions) {
                            this.removeOptions(this.$refs.officeField, clearSelected);
                        }
                        if (!data.results) {
                            this.noOfficesWarning = true;
                            this.selectedCity = null;
                            citySelect.val(null).trigger('change');

                            return;

                        }
                        for (let each of data.results) {
                            let newOption = new Option(each.text, each.id, false, false);
                            officeSelect.append(newOption);
                        }
                    });

            }
        },

        async postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        },
        showPaymentTooltip() {
            $('[data-toggle="tooltip"]').tooltip();
        },
        selectPaymentMethod(paymentMethod) {
            let eventStep6 = {
                event: 'eec.checkout',
                eventData: paymentMethod.method,
                action: 6,
            }
            EventManager.fire('gtm-event', eventStep6);

            if (paymentMethod ===
                this.selectedPaymentMethod) {
                return;
            }
            this.prevSelectedPaymentMethod = this.selectedPaymentMethod;
            this.selectedPaymentMethod = paymentMethod;

            this.handlePaymentMethodPromocode();

            this.selectedCard = null;

            if (paymentMethod.method === 'credit_card' && this.userCards.length > 1) {
                this.selectCard(this.userCards.filter(d => d.isPrimary === true)[0]);
            }
        },
        selectCard(card) {
            if (card === this.selectedCard) {
                return;
            }
            this.selectedCard = card;
        },
        selectPackage(packageOption) {
            this.selectedPackage = packageOption;
        },
        selectSlot(slot) {
            this.selectedSlot = slot;
        },
        handlePaymentMethodPromocode() {
            if (this.shouldRemovePromocode()) {
                this.removePromocode();
            }

            if (this.shouldApplyPromocode()) {
                this.promocodeText = this.selectedPaymentMethod.promocode;
                this.applyPromocode();
            }
        },
        shouldApplyPromocode() {
            if (this.selectedPaymentMethod !== null && this.selectedPaymentMethod.promocode
                && (this.promocode === null
                    || (this.prevSelectedPaymentMethod !== null
                        && this.promocode.code === this.prevSelectedPaymentMethod.promocode)))
                return true;
            return false;
        },

        shouldRemovePromocode() {
            if (this.selectedPaymentMethod !== null && !this.selectedPaymentMethod.promocode && this.promocode && this.isPromocodeInPaymentMethods()) {

                return true;
            }
            return false;
        },

        isPromocodeInPaymentMethods() {
            let code = this.promocode.code;
            return this.selectedOption.paymentMethods.filter(p => p.promocode === code).length > 0;
        },
        cartUpdated(data) {
            this.cart = JSON.parse(data);
            if (this.cart.rows.length === 0) {
                window.location.href = '/cart';
            }
        },
        cityChanged(data) {
            this.selectedCity = data;
            this.calcDeliveryPrice(this.deliveryPriceInCurrentCurrency);
            this.tryToPopulateOffices(true);
        },

        toggleIsGift() {
            this.isGift = !this.isGift & 1;
            this.selectedArea = null;
            this.selectedOption = null;
            this.selectedPaymentMethod = null;
            this.prevSelectedPaymentMethod = null;
            this.selectedCard = null;
            this.selectedCity = null;
            this.selectedDate = null;

            let isGift = 'not a gift';
            if (this.isGift) {
                isGift = 'gift';
            }
            let eventStep2 = {
                event: 'eec.checkout',
                eventData: isGift,
                action: 2,
            }
            EventManager.fire('gtm-event', eventStep2);

            if (this.isGift) {
                this.allowNewAddress = 1;
            } else {
                this.packaging = 0;
                this.selectedPackage = null;
            }
            if (this.selectedType && this.selectedType.areas.length === 1) {
                this.selectedArea = this.selectedType.areas[0];
            }
            if (!this.isGift) {
                this.toggleNewAddressSelection();
            }
            let select2 = $(this.$refs.cityField);
            select2.select2('destroy');

            let select2Country = $(this.$refs.countrySelect);
            select2Country.select2('destroy');

            this.$nextTick(() => {
                this.initCountrySelect2();
            });


        },

        togglePackaging() {
            this.packaging = !this.packaging & 1;

            if (!this.packaging) {
                this.selectedPackage = null;
            }
            /*     if (this.packaging) {
                     this.selectedPackage = this.packages[0];
                 } else {
                     this.selectedPackage = null;
                 }*/
        },

        toggleSlotSelection() {
            this.isSlotSelecting = !this.isSlotSelecting;
            if (!this.isSlotSelecting) {
                this.selectedSlot = null;
            }

        },
        toggleSaveAddress() {
            this.saveAddress = !this.saveAddress & 1;
        },

        toggleNewAddressSelection() {

            this.allowNewAddress = !this.allowNewAddress & 1;
            this.selectedUserAddress = null;

            if(this.allowNewAddress){
                this.$nextTick(() => {
                    this.initCountrySelect2();
                });
            }

        },


        preselectCity(city) {
            let select2 = $(this.$refs.cityField);
            let newOption = new Option(city.name, city.id, true, true);

            select2.append(newOption).trigger('change');
        },

        removeRow(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.removeRowUrl,
                method: 'POST',
                success: function (result) {
                    EventManager.fire('item-removed', result.cart);
                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CheckoutPage',
                    }
                    EventManager.fire('gtm-event', event);

                    let eventStep1 = {
                        event: 'eec.checkout',
                        eventData: 'Cart Page',
                        action: 1,
                        cart: JSON.parse(result.cart),
                    }

                    EventManager.fire('gtm-event', eventStep1);
                },
            });
        },
        addVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.variantId},
                url: _self.incrementUrl,
                method: 'POST',
                success: function (result) {
                    if (result.success !== false) {
                        _self.quantityAlert.visible = false;
                        EventManager.fire('item-added', result.cart);

                        let event = {
                            event: 'add_to_cart',
                            eventData: result.variant,
                            unique_event_id: result.unique_event_id,
                            action: 'CheckoutPage',
                        }
                        EventManager.fire('gtm-event', event);

                        let eventStep1 = {
                            event: 'eec.checkout',
                            eventData: 'Cart Page',
                            action: 1,
                            cart: JSON.parse(result.cart),
                        }

                        EventManager.fire('gtm-event', eventStep1);
                    } else {
                        _self.quantityAlert.visible = true;
                        _self.quantityAlert.productName = row.name;
                    }
                },
            });
        },
        decrementVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.decrementUrl,
                method: 'POST',
                success: function (result) {
                    _self.quantityAlert.visible = false;
                    EventManager.fire('item-removed', result.cart);

                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CheckoutPage',
                    }
                    EventManager.fire('gtm-event', event);


                    let eventStep1 = {
                        event: 'eec.checkout',
                        eventData: 'Cart Page',
                        action: 1,
                        cart: JSON.parse(result.cart)
                    }

                    EventManager.fire('gtm-event', eventStep1);
                },
            });
        },

        incrementRowQuantity(row) {
            if (row.quantity > 99) {
                return;
            }
            this.addVariantQuantity(row);

        },
        outOfStockProducts(){
            return this.cart.rows.filter(row => !row.inStock);
        },

        decrementQuantity(row) {
            if (row.quantity === 1) {
                return;
            }
            this.decrementVariantQuantity(row);
        },
        formattedRowPrice(row) {
            return this.formattedPrice(row.quantity * row.price);
        },
        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },
        togglePromocodeField() {
            this.showPromocode = !this.showPromocode;
        },
        applyPromocode() {
            let _self = this;
            $.ajax({
                data: {code: _self.promocodeText},
                url: _self.promocodeUrl,
                method: 'POST',
                success: function (result) {
                    if (result.success === false) {
                        _self.promocodeError = result.error;
                    } else {
                        let data = JSON.parse(result)
                        _self.promocodeError = null;
                        _self.promocode = data.promocode;
                        _self.hasPromocode = true;
                        EventManager.fire('item-added', result);
                    }
                },
            });
        },

        removePromocode() {
            let _self = this;
            $.ajax({
                url: _self.removePromocodeUrl,
                method: 'POST',
                success: function (result) {
                    _self.hasPromocode = false;
                    _self.promocode = null;
                    EventManager.fire('item-added', result);
                    _self.handlePaymentMethodPromocode();
                },
            });
        },

        qualifiesForFreeDelivery() {
            if (this.selectedOption && !this.selectedOption.allowFreeShipping) {
                return false;
            }
            // Безплатна доставка за софия при над 50лв стойност, за сега не е нужно
            // if (this.selectedCity && this.selectedCity == 4389) {
            //     this.freeDeliveryAmount = 50;
            //     return this.cart.total >= this.freeDeliveryAmount;
            // }

            return this.cart.total >= this.selectedCountry.freeDeliveryAmount && this.selectedCountry.freeDeliveryActive;
        },

        calcDeliveryPrice(price) {

            if (this.cart.total >= this.selectedCountry.freeDeliveryAmount) {
                return this.formattedPrice(0);
            } else {
                return this.formattedPrice(price);
            }
        },
        deliveryTypeHasDifferentPrices(type) {

            if (type.areas.length === 1 && type.areas[0].options.length === 1) {
                return false;
            }

            let price = type.areas[0].options[0].priceInCurrentCurrency;
            let hasDifferentPrices = false;
            type.areas.map(function (area) {
                area.options.map(function (option) {
                    if (price !== option.priceInCurrentCurrency) {
                        hasDifferentPrices = true;
                    }
                })
            })

            return hasDifferentPrices;

        },
        calcOptionDeliveryPrice(option) {
            if (option === this.selectedOption && !option.allowFreeShipping) {
                return this.formattedPrice(option.priceInCurrentCurrency);
            }

            if (!option.allowFreeShipping && this.selectedOption !== option) {
                return this.formattedPrice(option.priceInCurrentCurrency);
            }

            if (option.city && option.city == 4389 && this.cart.total >= 50) {
                return this.formattedPrice(0);
            }

            if (this.cart.total >= this.selectedCountry.freeDeliveryAmount) {
                return this.formattedPrice(0);
            }

            return this.formattedPrice(option.priceInCurrentCurrency);

        },

        isIOS() {
            return [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod'
                ].includes(navigator.platform)
                // iPad on iOS 13 detection
                || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        },

        concatenateAttributeValues(attributes) {
            let str = '';
            for (let [index, attribute] of attributes.entries()) {
                if (index !== 0) {
                    str += ', '
                }
                if (attribute.showUnit) {
                    str += attribute.unit + ' x '
                }
                str += ' ' + attribute.value;

            }
            return str;
        },

        getAvailableTypes() {
            if (this.isGift) {
                return this.selectedCountry.types.filter(d => d.availableForGift === true);
            }
            return this.selectedCountry.types;
        },

        getAvailableOptions(area) {

            let options = area.options;

            if (this.selectedDate) {
                options = options.filter(o => o.isAvailableWeekends === true)
            }
            return options;
        },

        getAvailableMethods() {

            let methods = this.selectedOption.paymentMethods;

            if (this.calcTotal(true) === 0) {
                methods = methods.filter(pm => pm.isFree === true);
            } else {
                methods = methods.filter(pm => pm.isFree === false);
            }
            if (this.isGift) {
                methods = methods.filter(pm => pm.isAvailableForGift === true);
            }

            let isIos = this.isIOS();

            if (!isIos) {
                methods = methods.filter(pm => pm.onlyIos === false);
            }

            if (methods.length === 1) {
                this.selectPaymentMethod(methods[0]);

            }
            return methods;
        },
        formattedDate(datetimeString, withYear=false) {

            let date = new Date(datetimeString);
            let dateOptions = {
                day: 'numeric',
                month: 'numeric',
                weekday: 'long'
            };
            if(withYear){
                dateOptions = {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    weekday: 'long'
                };
            }
            return new Intl.DateTimeFormat(this.locale, dateOptions).format(date);
        },
        formattedSlotTime(slot) {
            let startDate = new Date(slot.startTime.date);
            let endDate = new Date(slot.endTime.date);

            startDate = Intl.DateTimeFormat(this.locale, {timeStyle: 'short'}).format(startDate);
            endDate = Intl.DateTimeFormat(this.locale, {timeStyle: 'short'}).format(endDate);
            return startDate + ' - ' + endDate;
        },
        calcTotal(includeDelivery) {

            let total = this.cart.total;
            if (this.selectedPackage) {
                total += this.selectedPackage.price;
            }
            if (includeDelivery) {
                let deliveryPrice = this.deliveryPriceInCurrentCurrency;

                if (this.hasPromocode && this.promocode.isValidForShipping) {
                    deliveryPrice = this.calcDeliveryAfterPromocode()
                    this.deliveryPriceDiscount = 0 - (this.deliveryPrice - deliveryPrice);
                }
                total += deliveryPrice ?? 0;
            }
            return Math.round(total * 100 + Number.EPSILON) / 100;
        },

        shouldShowAddressFields() {
            if (this.isGift || (this.selectedType && this.selectedType.isPhysical === true)) {
                return true;
            }
            return false;
        },
        openCalendar(e) {
            e.stopPropagation();
            this.$refs.datepicker.showCalendar();
            document.addEventListener("click", this.documentOnClick);
        },
        closeCalendar() {
            this.$refs.datepicker.close();
            document.removeEventListener("click", this.documentOnClick);
        },
        checkDeliveryPromocodeOption() {
            let _self = this;
            let result = false;

            this.promocode.deliveryPromocodeOptions.forEach(function (deliveryPromocodeOption, index) {
                if (deliveryPromocodeOption.isAvailable && deliveryPromocodeOption.optionId == _self.selectedOption.id) {
                    result = true;
                }
            });

            return result;
        },
        calcDeliveryAfterPromocode() {
            let computedPrice = this.deliveryPriceInCurrentCurrency;
            if (!this.deliveryPriceInCurrentCurrency) {
                return this.deliveryPriceInCurrentCurrency;
            }

            if (this.promocode.type === 1) {
                computedPrice = computedPrice - (computedPrice * (this.promocode.discount / 100));
                computedPrice = Math.round(computedPrice * 100 + Number.EPSILON) / 100
            } else if (this.promocode.type === 2) {
                if (this.promocode.isDeliveryPromocode && this.checkDeliveryPromocodeOption()) {
                    return (this.deliveryPriceInCurrentCurrency - this.promocode.discount) > 0 ? this.deliveryPriceInCurrentCurrency - this.promocode.discount : 0;
                }
                let originalTotal = this.cart.totalPriceAfterDiscount;
                let total = this.calcTotal(false);
                if ((this.promocode.discount - originalTotal - this.cart.total > 0 && this.promocode.isValidForShipping)) {
                    return this.promocode.discount - originalTotal - this.cart.total > computedPrice ? 0 : computedPrice - (this.promocode.discount - originalTotal - this.cart.total);
                }

                if (originalTotal >= this.promocode.discount) {
                    return computedPrice;
                }

                if (total === 0 && ((originalTotal + computedPrice) <= this.promocode.discount)) {
                    return 0;
                }

                if (total === 0 && ((originalTotal + computedPrice) > this.promocode.discount)) {
                    return (originalTotal + computedPrice) - this.promocode.discount;
                }

            }


            return computedPrice;
        }


    },

    computed: {
        formattedTotal() {
            if (this.qualifiesForFreeDelivery()) {
                return this.formattedPrice(this.calcTotal(false))
            }

            return this.formattedPrice(this.calcTotal(true))

        },

        computedDeliveryPrice() {
            if (this.qualifiesForFreeDelivery()) {
                return 0;
            }
            let computedPrice = this.deliveryPrice;
            if (this.hasPromocode && this.promocode.isValidForShipping) {
                computedPrice = this.calcDeliveryAfterPromocode()
            }
            return computedPrice;
        },

        formattedOriginalTotal() {
            return this.formattedPrice(this.cart.originalTotal)
        },
        amountToFreeShipping() {
            return this.formattedPrice(this.selectedCountry.freeDeliveryAmount - this.cart.total);
        },
        formattedDiscount() {
            //return '-' + this.formattedPrice(this.cart.discount);
            return this.formattedPrice(this.cart.discount);
        },
        formattedLoyaltyProgramDiscount() {
            return '-' + this.formattedPrice(this.cart.loyaltyProgramDiscount);
        },
        selectedSlotId() {
            if (this.selectedSlot) {
                return this.selectedSlot.id;
            } else {
                return null;
            }
        }
    }
});