import Vue from 'vue/dist/vue.esm.js';

import '../../css/ebooksbridge/stripe.css'

Vue.component('stripe-elements', {
    template: '#stripe-elements',
    delimiters: ['[[', ']]'],
    props: {
        clientId: {
            required: true,
            type: String,
        },
        purchase: {
            required: true,
            type: Object,
        },
        redirectUrl: {
            required: true,
            type: String,
        },
    },

    created() {
        this.isLoading = true;
        this.createScript();
    },

    mounted() {
        document
            .querySelector("#payment-form")
            .addEventListener("submit", this.handleSubmit);

        //EventManager.listen('filter-applied', this.filterApplied);
    },

    data() {
        return {
            isLoading: false,
            stripe: null,
            elements: null,
        }
    },
    methods: {

        async handleSubmit(e) {
            e.preventDefault();
            this.setLoading(true);

            let _self = this;
            let elements = _self.elements;
            const {error} = await this.stripe.confirmPayment({
                elements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: _self.redirectUrl,
                },
            });

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                this.showMessage(error.message);
            } else {
                this.showMessage("An unexpected error occurred.");
            }

            this.setLoading(false);
        },

        async checkStatus() {
            const clientSecret = new URLSearchParams(window.location.search).get(
                "payment_intent_client_secret"
            );

            if (!clientSecret) {
                return;
            }

            const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

            switch (paymentIntent.status) {
                case "succeeded":
                    this.showMessage("Payment succeeded!");
                    break;
                case "processing":
                    this.showMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    this.showMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    this.showMessage("Something went wrong.");
                    break;
            }
        },

        showMessage(messageText) {
            const messageContainer = document.querySelector("#payment-message");

            messageContainer.classList.remove("hidden");
            messageContainer.textContent = messageText;

            setTimeout(function () {
                messageContainer.classList.add("hidden");
                messageText.textContent = "";
            }, 4000);
        },

        setLoading(isLoading) {
            if (isLoading) {
                // Disable the button and show a spinner
                document.querySelector("#submit").disabled = true;
                document.querySelector("#spinner").classList.remove("hidden");
                document.querySelector("#button-text").classList.add("hidden");
            } else {
                document.querySelector("#submit").disabled = false;
                document.querySelector("#spinner").classList.add("hidden");
                document.querySelector("#button-text").classList.remove("hidden");
            }
        },

        createScript() {

            const script = document.createElement("script");
            script.src = "https://js.stripe.com/v3/";
            script.addEventListener("load", this.setLoaded);
            document.body.appendChild(script);
        },

        setLoaded() {
            this.isLoading = false;

            let _self = this;

            let clientSecret = _self.purchase.clientSecret;

            this.stripe = window.Stripe(this.clientId);

            const appearance = { /* appearance */};
            const options = { /* options */};
            _self.elements = this.stripe.elements({clientSecret, appearance});
            const paymentElement = _self.elements.create('payment', options);
            paymentElement.mount('#payment-element');


        }
    }
});