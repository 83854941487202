import Vue from 'vue/dist/vue.esm.js';

Vue.component('add-to-cart', {
    template: `#add-to-cart`,
    delimiters: ['[[', ']]'],
    props: {
        addToCartUrl: {
            required: true,
            type: String,
        },
        context: {
            required: true,
            type: String,
        },
        product: {
            required: true,
            type: Object,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        useFirstVariant: {
            required: false,
            type: Boolean,
            default: false,

        },
    },


    mounted() {
        this.buildAttributes();

        if (this.product.variants.length === 1) {
            this.selectedVariant = this.defaultVariant;
            for (let attribute of this.attributes) {

                for (let option of attribute.availableOptions) {
                    option.selected = true;
                }
            }
        } else {
            for (let single_variant of this.product.variants) {
                if (single_variant.quantity > 0) {
                //    this.selectedVariant = single_variant;
                    break;
                }
            }
        }
        if (this.selectedVariant && this.selectedVariant.options && this.selectedVariant.options.length > 0) {
            this.selectedVariant.options[0].value.selected = true;
            this.refreshAvailableAttributeOptions();
            this.checkUnselectedAttributes()
            this.updateSku();
            this.updateGallery();
        }


        if(this.selectedVariant){
            this.checkAvailability();
        }else{
            $('.in-stock').hide();
        }

    },

    data() {
        return {
            selectedOptions: [],
            defaultVariant: this.product.defaultVariant,
            selectedVariant: null,
            variants: this.product.variants,
            attributes: [],
            gallery: this.product.gallery,
            availableVariants: this.product.variants,
            quantity: 1,
            maxQuantityReached: false,
            variantsCount: this.product.variants.length,
            availableForAdd: true
        }
    },

    methods: {
        buildAttributes() {

            let _self = this;
            this.variants.map(function (variant) {

                let selected = variant.options.length === 1;

                variant.options.map(function (option) {
                    let attributeData = _self.attributes.filter(a => a.slug === option.attribute.slug)[0];

                    if (attributeData === undefined) {
                        attributeData = {
                            availableOptions: [],
                            name: option.attribute.name,
                            slug: option.attribute.slug,
                            showUnit: option.attribute.showUnit,
                            orderStrategy: option.attribute.orderStrategy,
                            image: option.attribute.image,
                            selected: true,
                            selectedValue: null
                        };

                        _self.attributes.push(attributeData);
                    }

                    option.value.unit = variant.unit;
                    option.value.unitDiscount = variant.unitDiscount;
                    option.value.price = variant.price;
                    option.value.quantity = variant.quantity;
                    if (attributeData.availableOptions.filter(v => v.id === option.value.id && v.unit === option.value.unit).length === 0) {
                        //option.value.selected = selected;
                        attributeData.availableOptions.push(option.value);
                    }

                })
            });


        },

        selectAttribute(option, attribute) {
            let _self = this;

            option.selected = !option.selected;
            if (option.selected) {
                attribute.selectedValue = option.label;
            } else {
                attribute.selectedValue = null;
            }
            attribute.availableOptions.map(function (o) {
                if ((o.id !== option.id) || (o.id === option.id && o.unit !== option.unit)) {
                    o.selected = false;
                    _self.selectedOptions = _self.selectedOptions.filter(op => op.selected);
                }
            });

            if (option.selected) {
                _self.selectedOptions.push(option);
            }


            let tempAvailableVariants = [];
            this.variants.map(function (variant) {
                if (_self.variantHasAllAttributeValues(variant, _self.selectedOptions)) {
                    tempAvailableVariants.push(variant);
                }
            });

            if (tempAvailableVariants.length === 1) {
                this.selectedVariant = tempAvailableVariants[0];
                this.maxQuantityReached = false;
                this.fixSelectedQuantity();
            } else if (tempAvailableVariants.length === 0) {
                //this.resetAllExcept(option);
                this.selectedVariant = null;
            } else {
                this.selectedVariant = null;
            }
            this.refreshAvailableAttributeOptions();
            this.checkUnselectedAttributes();
            this.updateSku();
            this.checkAvailability();
            this.updateGallery();
            this.checkStockStatus();
        },

        resetAllExcept(lastOption) {
            for (let selectedOption of this.selectedOptions) {
                if (!this.matchOption(selectedOption, lastOption)) {
                    // selectedOption.selected = false;
                }
            }

            this.selectedOptions = [lastOption];

        },
        checkAvailability() {
            if (this.selectedVariant === null || this.selectedVariant.quantity <= 0) {
                $('.in-stock').hide();
                $('.not-in-stock').show();
            } else {
                $('.in-stock').show();
                $('.not-in-stock').hide();
            }
        },
        updateSku() {
            let sku = this.selectedVariant ? this.selectedVariant.sku : this.product.sku;
            $('#product-sku').text(sku);

        },

        updateGallery() {
            let gallery = this.selectedVariant ? this.selectedVariant.gallery : this.product.gallery;
            if (this.gallery != gallery) {
                this.gallery = gallery;
                EventManager.fire('gallery-changed', this.gallery);
            }

        },

        checkStockStatus() {
            let isAvaiableCombination = true;
            for (let key in this.selectedOptions) {
                let option = (this.selectedOptions[key]);
                if (!option.available) {
                    isAvaiableCombination = false;
                }
            }
            if (this.attributes.length == 1) {
                isAvaiableCombination = this.selectedOptions[0].quantity > 0;
            }
            if (this.selectedOptions.length == 0) {
                this.availableForAdd = true;
            } else if (isAvaiableCombination) {
                this.availableForAdd = true;
            } else {
                this.availableForAdd = false;
            }

        },
        refreshAvailableAttributeOptions() {

            for (let attribute of this.attributes) {
                for (let option of attribute.availableOptions) {
                    option.available = this.isOptionAvailable(attribute, option);
                }
            }
        },

        isOptionAvailable(attribute, option) {
            if (this.attributes.length == 1) {
                return true;
            }
            let remainingVariants = this.variants.filter(v => this.variantHasAttributeValue(v, option));

            for (let eachAttribute of this.attributes) {
                if (eachAttribute === attribute) {
                    continue;
                }

                for (let selectedOption of eachAttribute.availableOptions.filter(o => o.selected)) {
                    remainingVariants = remainingVariants.filter(v => this.variantHasAttributeValue(v, selectedOption));
                }
            }

            return remainingVariants.length > 0;
        },

        variantHasAllAttributeValues(variant, selectedOptions) {

            for (let selectedOption of selectedOptions) {
                if (!this.variantHasAttributeValue(variant, selectedOption)) {
                    return false;
                }
            }

            return true;
        },

        variantHasAttributeValue(variant, selectedOption) {

            for (let option of variant.options) {

                if (option.value.id === selectedOption.id && option.value.unit === selectedOption.unit) {
                    return true;
                }
            }
            return false;
        },

        matchOption(a, b) {
            return a.id === b.id && a.unit === b.unit;
        },

        sortedAttributeAvailableOptions(attribute) {

            switch (attribute.orderStrategy) {
                case 0:
                    return attribute.availableOptions;
                case 1:
                    return attribute.availableOptions.slice().sort((a, b) => {
                        return a.value.localeCompare(b.value);
                    });
                case 2:
                    return attribute.availableOptions.slice().sort((a, b) => {
                        return -a.value.localeCompare(b.value);
                    });
                case 3:
                    return this.sortDressSizes(attribute);
                case 4:
                    return this.sortHookSizes(attribute);
                default:
                    console.error('Invalid attribute orderStrategy value');
            }

        },

        sortDressSizes(attribute) {
            return attribute.availableOptions.slice().sort((a, b) => {
                const sizes = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"];
                return sizes.indexOf(a.value) - sizes.indexOf(b.value);
            });
        },

        sortHookSizes(attribute) {
            function getRank(name) {
                if (name.slice(-2) === "/0") {
                    const numeralPart = name.slice(0, name.indexOf('/') + 1);
                    return -parseInt(numeralPart) * 1000;
                }

                if (name[0] === "0") {
                    return -name.length;
                }

                return parseInt(name);
            }

            function compare(a, b) {
                return getRank(a.value) - getRank(b.value);
            }

            return attribute.availableOptions.slice().sort(compare);
        },
        attributeComparer(a, b) {

            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;

        },
        addToCart() {
            if (!this.selectedVariant) {
                this.checkUnselectedAttributes();
                return;
            }
            let container = this.$refs.personalisationFormContainer;

            let formData = [];

            if (container) {
                let personalisationForm = container.querySelector('form');
                if (!personalisationForm.checkValidity()) {
                    personalisationForm.reportValidity();
                    return;
                }

                let submitUrl = personalisationForm.dataset.submitUrl,
                    formData = new FormData(personalisationForm)

                let _self = this;
                $.ajax({
                    url: submitUrl,
                    data: formData,
                    method: 'POST',
                    processData: false,
                    contentType: false,
                    success: function (response) {
                        if (response.success) {
                            container.innerHTML = response.content;
                            _self.sendAddToCartRequest(formData);
                        } else {
                            container.innerHTML = response.content;
                        }
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });

            } else {
                this.sendAddToCartRequest(formData);
            }


        },

        sendAddToCartRequest(formData) {

            let additionalData = {};
            if (formData instanceof FormData) {
                formData.forEach(function (value, index) {
                    if (!index.includes("_token"))
                        additionalData[index] = value;
                })
            }

            let _self = this;
            $.ajax({
                data: {id: _self.selectedVariant.id, quantity: _self.quantity, additionalData: JSON.stringify(additionalData)},
                url: _self.addToCartUrl,
                method: 'POST',
                success: function (result) {
                    _self.successAjax(result)
                },
            });
        },

        checkUnselectedAttributes() {
            for (let attribute of this.attributes) {
                if (attribute.availableOptions.filter(a => a.selected === true).length === 0) {
                    attribute.selected = false;
                } else {
                    attribute.selected = true;
                }
            }
        },
        fixSelectedQuantity() {
            if (this.quantity > this.selectedVariantQuantity) {
                this.quantity = this.selectedVariantQuantity;
            } else if (this.quantity == 0 && this.selectedVariantQuantity > 0) {
                this.quantity = 1
            }
        },
        incrementQuantity() {
            let maxQuantity = this.selectedVariantQuantity ?? 99;
            if (this.quantity > maxQuantity) {
                return;
            }
            this.quantity = parseInt(this.quantity) + 1;
        },

        decrementQuantity() {
            if (this.quantity === 1) {
                return;
            }
            if (this.quantity === 0) {
                return;
            }
            this.quantity--;
        },

        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price) + ' ' + this.currency;
        },

        successAjax(result) {
            let _self = this;
            this.maxQuantityReached = false;

            let successMessage = $('#success');
            let failMessage = $('#fail');

            if (result.success === false) {
                this.maxQuantityReached = true;
                return;
            } else {
                successMessage.show();
                failMessage.hide();
            }

            let modal = $('#checkout-modal');
            modal.modal('show');
            EventManager.fire('item-added', result.cart);

            let event = {
                event: 'add_to_cart',
                eventData: result.variant,
                unique_event_id: result.unique_event_id,
                action: _self.context,
            }
            EventManager.fire('gtm-event', event);

        }

    },

    computed: {
        selectedVariantPrice() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            if (!this.product.isReleased) {
                if(variant.price === 0){
                    return 'Unknown';
                }
            }

            return this.formattedPrice(variant.price);
        },
        selectedVariantDiscountedPrice() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            if (!this.product.isReleased) {
                if(variant.price === 0){
                    return 'Unknown';
                }
            }

            if (variant.discountedPrice || variant.discountedPrice === 0) {
                return this.formattedPrice(variant.discountedPrice);
            } else {
                return false;
            }
        },
        selectedVariantQuantity() {
            let variant = this.selectedVariant ?? this.defaultVariant;
            return variant.quantity;
        }
    }
});